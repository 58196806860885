import React from 'react';
import App from 'Src/app/';
import Main from 'Components/Main';
import Display from 'Components/Display';

const RussianPage = () => {
  return (
    <App lang="ru">
      <Display>
        <Main />
      </Display>
    </App>
  );
};

export default RussianPage;
